export const config = {
    RPC_URL: 'https://mainnet-node.like.co/rpc/',
    REST_URL: 'https://mainnet-node.like.co',
    EXPLORER_URL: 'https://likecoin.bigdipper.live',
    STAKING_URL: 'https://likecoin.omniflix.co/stake',
    NETWORK_NAME: 'LikeCoin',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'likecoin-mainnet-2',
    CHAIN_NAME: 'LikeCoin',
    COIN_DENOM: 'LIKE',
    COIN_MINIMAL_DENOM: 'nanolike',
    COIN_DECIMALS: 9,
    PREFIX: 'like',
    COIN_TYPE: 118,
    COSMOSTAION: null,
    COINGECKO_ID: 'likecoin',
    GAS_PRICE_STEP_LOW: 0.01,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
